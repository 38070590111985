.editor-container-dark {
    border: none; /* Remove border from toolbar */
    background-color: #FFFFFF0F; /* Darken the toolbar background */
    color: #fff;
  }

  .editor-container-light {
    border: none; /* Remove border from toolbar */
    background-color: #f1f3f4; /* Darken the toolbar background */
    color: #2b2e48;
  }

  .editor-dark {
    color: #fff;
  }

  .editor-light {
    color: #2b2e48;
  }

  .ql-toolbar.ql-snow{
    border: none; /* Remove border from toolbar */
  }

  .ql-container.ql-snow  {
    border: none; /* Remove border from toolbar */
  }

  .ql-snow.ql-toolbar .ql-picker-label{
    color: #fff;
  }

  .ql-snow.ql-toolbar .ql-picker-label.ql-active{
    color: rgb(23, 249, 218);
    stroke: rgb(23, 249, 218);
  }

  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke{
    stroke: rgb(23, 249, 218);
  }

  .ql-snow.ql-toolbar .ql-picker-label .ql-stroke{
    stroke: #fff;
  }

  .ql-snow.ql-toolbar button.ql-active .ql-stroke{
    stroke: rgb(23, 249, 218);
  }

  .ql-snow.ql-toolbar button .ql-stroke{
    stroke: #fff;
  }

  .ql-snow .ql-fill{
    fill: #fff;
  }

  .ql-editor iframe {

  }
  .ql-toolbar button.ql-iframe::before {
    /* border: 1px solid white; */
    content: "🌐"; /* Example icon or label for iframe button */
    /* font-size: 16px; */
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
  }
  .modal-content h3 {
    font-weight: bold;
    text-align: center;
    margin-top: 0;
  }
  .modal-content label {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
  }
  .modal-content input,
  .modal-content select {
    border: 1px solid #ccc;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 6px;
    padding: 5px 12px;
    font-size: 14px;
  }
  .modal-content button {
    margin-right: 10px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-weight: 500;
  }