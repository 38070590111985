@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Athiti:wght@500;700&family=Barlow:wght@400;600;700;900&family=Montserrat:wght@700&family=Play:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

@keyframes flash {
  0% {
    background-color: #978a27; /* A yellow highlight */
  }
  100% {
    background-color: inherit;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flash-animation {
  animation: flash 1s ease-in-out;
}
.glassmorphism {
  background-color: rgba(255, 255, 255, 0.08);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}

.glassmorphism-secondary {
  background-color: rgba(255, 255, 255, 0.08);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.bg-opacity {
  background-color: rgba(255, 255, 255, 0.222);
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
}

.dashboardWrapper {
  height: calc(100vh - 64px);
  overflow: scroll;
}

.profileDesc {
  width: calc(100% - 310px);
}

.profileEditUserPhoto {
  height: calc(19vw);
}

.gradientBgBorderImg {
  height: calc(24vw);
}

.awardImg {
  width: 15%;
}

.awardImgSmall {
  width: 27%;
  margin: 0px 3% 0px 3%;
  height: fit-content;
}

.absoluteGradientImg {
  /* height: 70%; */
}

/* .awardImgSmall:nth-child(3) {
}
.awardImgSmall:nth-child(6) {
}
.awardImgSmall:nth-child(9) {
} */
.profileLogbookPic {
  width: 8vw;
  height: 8vw;
}

textarea:focus,
input:focus {
  outline: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  display: none;
}

.memberImgWrapper {
  width: 23%;
}

.todayLeaderImgWrapper {
  width: 16%;
}

.memberImg {
  height: 11.5vw;
}

.gm-style-iw {
  padding: 0px !important;
}

button.gm-ui-hover-effect {
  padding-top: 30px;
  padding-right: 30px;
}

@media only screen and (orientation: landscape) and (min-height: 682px) {
  .settingNavBar {
    bottom: 0px;
    /* position: absolute; */
  }
}

@media only screen and (max-width: 1024px) {
  .dashboardWrapper {
    height: calc(100vh - 56px);
    overflow: scroll;
  }
  .profileDesc {
    width: calc(100% - 260px);
  }
  .profileEditUserPhoto {
    height: calc(26vw);
  }
  .memberImg {
    height: 22vw;
  }
  .memberImgWrapper {
    width: 31%;
  }
  .todayLeaderImgWrapper {
    width: 31%;
  }
}
.map-container {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .profileDesc {
    width: 100%;
  }
  .awardImg {
    width: 30%;
  }
  .profileEditUserPhoto {
    height: calc(29vw);
  }
  .profileLogbookPic {
    width: 22vw;
    height: 22vw;
  }
  .memberImgWrapper {
    width: 47.5%;
  }
  .todayLeaderImgWrapper {
    width: 47.5%;
  }
  .memberImg {
    height: 27.5vw;
  }
  .responsive-flex {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 640px) {
  .profileEditUserPhoto {
    height: 190px;
  }
  .memberImgWrapper {
    width: 100%;
  }
  .todayLeaderImgWrapper {
    width: 45%;
  }
  .memberImg {
    height: 170px;
  }
}

@media only screen and (max-width: 370px) {
  .awardImg {
    width: 47%;
  }
  .profileLogbookPic {
    width: 35vw;
    height: 35vw;
  }
}

.custom-bg {
  background-image: url("./assets/Images/spaceBg.svg");
  background-size: cover;
  background-position: center;
}

.timer-bg {
  background-image: url("./assets/Images/timerBg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.text-shadow {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.transparent-white {
  background-color: rgba(255, 255, 255, 0.222);
}

.gradient-inset-shadow {
  border-radius: 100px; /* Example border radius */
  color: white; /* Text color */
  background: linear-gradient(to right, #07b8ff, #35b7ff);
  box-shadow: inset 0 2px 15px 0 rgba(0, 0, 0, 0.315); /* Light inset shadow */
}

@media screen and (max-width: 40em) {
  .responsiveTable tbody tr {
    border: 1px solid #000;
    padding: 0.25em;
  }
}

.dropdown-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.no-close-button-on-info-windows .gm-style-iw > div:first-child {
  display: none;
}

.no-select {
  user-select: none;
}

.react-time-picker__wrapper {
  border: none !important;
}

/* Custom scrollbar styling */
.custom-scrollbar {
  /* Subtle fade at the bottom to indicate scrollable content */
  mask-image: linear-gradient(
    to bottom,
    black calc(100% - 24px),
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    black calc(100% - 24px),
    transparent 100%
  );

  /* Smooth scrolling for better UX */
  scroll-behavior: smooth;

  /* Firefox scrollbar styling */
  scrollbar-width: thin;
  scrollbar-color: #17f9da #151541;

  /* Prevent horizontal scrollbar when not needed */
  overflow-x: hidden;

  /* Add some padding to prevent content from being cut off by the fade */
  padding-bottom: 24px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: block;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(23, 249, 218, 0.05);
  border-radius: 4px;
  /* Add subtle inner shadow to track */
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #17f9da 0%, #9945ff 100%);
  border-radius: 4px;
  border: 2px solid #151541;
  /* Add transition for smooth hover effect */
  transition: background 0.3s ease;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #9945ff 0%, #17f9da 100%);
  /* Add subtle glow effect on hover */
  box-shadow: 0 0 8px rgba(23, 249, 218, 0.4);
}

/* Add these styles for sticky header */
.custom-scrollbar table thead {
  position: sticky;
  top: 0;
  z-index: 20;
  background: #3c3c58; /* Match your header background */
}

/* Add shadow to header */
.custom-scrollbar table thead::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
}

/* Main Content Scrollbar Start */
/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 5px;
  border: 2px solid var(--scrollbar-track);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

/* Light theme variables */
:root {
  --scrollbar-track: #f4f2f1;
  --scrollbar-thumb: #c1c1c1;
  --scrollbar-thumb-hover: #a8a8a8;
}

/* Dark theme variables */
:root.dark {
  --scrollbar-track: transparent;
  --scrollbar-thumb: #31336e;
  --scrollbar-thumb-hover: #3f4185;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

html, body {
  min-height: 100vh;
  overflow-y: auto;
}
/* Main Content Scrollbar End */

/* Add this new class for carousel scrollbar hiding */
.carousel-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.carousel-scrollbar::-webkit-scrollbar {
  display: none;  /* Chrome, Safari and Opera */
}
